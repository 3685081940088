






















































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { StrategyListObj } from '@/views/setting/types'

@Component({ name: 'templateManage' })
export default class templateManage extends VueBase {
  private tableData: Array<StrategyListObj> = []
  private page = 1
  private page_size = 20
  private total = 0
  private name = ''
  private async stateChange(row: any) {
    this.loadingStart()
    const res = await this.services.setting.update_scan_strategy({
      ...row,
      ...{ status: row.status === 0 ? 1 : 0 },
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('状态变更成功')
      this.getTableData()
    } else {
      this.$message.error('状态变更失败，请重试')
    }
  }

  private handleSizeChange(val: number) {
    this.page_size = val
    this.getTableData()
  }

  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  async created() {
    this.loadingStart()
    await this.getTableData()
    this.loadingDone()
  }
  get userInfo(): { username: string } {
    return this.$store.getters.userInfo
  }

  private async getTableData() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.get_scan_strategy({
        page: this.page,
        page_size: this.page_size,
        name: this.name,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    if (data.length === 0 && this.page > 1) {
      this.page--
      await this.getTableData()
      return
    }
    this.tableData = data
    this.total = page.alltotal
  }

  toPath(view: any, id: any) {
    this.$router.push({
      name: 'template',
      query: {
        view,
        id,
      },
    })
  }

  private templateDialogAdd() {
    this.$router.push({ name: 'template' })
  }
  private templateDialogEdit(row: any) {
    this.$router.push({
      name: 'template',
      query: {
        id: row.id,
      },
    })
  }
  private async templateDialogDelete(row: any) {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: "cancelButtonClass",
      confirmButtonClass: "delete-btn",
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前策略模版?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      const res = await this.services.setting.delete_scan_strategy_one({
        id: row.id,
      })
      if (res.status === 201) {
        this.$message.success('策略模板删除成功')
        this.getTableData()
      } else {
        this.$message.error('策略模板删除失败，请重试')
      }
    })
  }
}
